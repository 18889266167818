<template>
  <div v-if="!mounted">Chargement ...</div>
  <div v-else>
    <v-row>
      <v-col cols="10">
        <v-alert border="left" :color="colorEtapeAffiliation" dark>
          {{ currentEtapeAffiliation.NomEtapeAffiliation }}
        </v-alert>
      </v-col>
    </v-row>

    <ValidateForm @validated="submit" :loading="loading">
      <v-row>
        <v-col cols="4">
          <v-row class="mt-4">
            <v-col cols="12" class="pb-0">
              <p class="font-weight-bold">Correspondant</p>
            </v-col>
            <v-col cols="12">
              <userAssign
                :user="currentNomCorrespondant"
                :context="{
                  ID_Structure: currentStructureId,
                  ID_Saison: selectedSaison.id,
                  hasCodeFederal: hasCodeFederal,
                }"
                @userSelected="userSelectedNomCorrespondant"
              />
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="4">
          <v-row class="mt-4">
            <v-col cols="12" class="pb-0">
              <p class="font-weight-bold">Adresse correspondant</p>
            </v-col>
            <v-col cols="12">
              <adressAssign
                :adresse="currentAdresseCorrespondance"
                :context="{
                  ID_Structure: currentStructureId,
                  type: 'correspondance',
                  hasCodeFederal: hasCodeFederal,
                }"
                @onCardUpdate="adresseSelected"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-row class="mt-4">
        <v-col cols="12">
          <inputText v-model="JUR_Siret" label="N° SIRET" rules="siret" />
          <inputText
            v-model="JUR_AgrementJeunesseSports"
            label="Agrément Jeunesse et sports"
          />
        </v-col>
      </v-row>

      <v-row class="mt-4">
        <v-col cols="12" class="pb-0">
          <p class="font-weight-bold">Liste des dirigeants déclarés</p>
        </v-col>
        <v-col cols="12" lg="4">
          <p>Président</p>
          <userAssign
            :user="userPresident"
            :context="{
              ID_Structure: currentStructureId,
              ID_Saison: selectedSaison.id,
              hasCodeFederal: hasCodeFederal,
            }"
            @userSelected="userFonctionPresidentSelected"
          />
        </v-col>
        <v-col cols="12" lg="4">
          <p>Secrétaire</p>
          <userAssign
            :user="userSecretaire"
            :context="{
              ID_Structure: currentStructureId,
              ID_Saison: selectedSaison.id,
              hasCodeFederal: hasCodeFederal,
            }"
            @userSelected="userFonctionSecretaireSelected"
          />
        </v-col>
        <v-col cols="12" lg="4">
          <p>Trésorier</p>
          <userAssign
            :user="userTresorier"
            :context="{
              ID_Structure: currentStructureId,
              ID_Saison: selectedSaison.id,
              hasCodeFederal: hasCodeFederal,
            }"
            @userSelected="userFonctionTresorierSelected"
          />
        </v-col>
      </v-row>

      <v-row class="mt-6">
        <v-col cols="12">
          <v-btn
            v-if="canSubmit && hasCodeFederal"
            type="submit"
            :loading="loading"
            class="btn btn-primary mt-5"
          >
            Enregistrer les modifications
          </v-btn>
          <v-tooltip v-else top>
            <template v-slot:activator="{ on }">
              <span v-on="on">
                <v-btn
                  type="submit"
                  :disabled="true"
                  class="btn btn-primary mt-5"
                >
                  Enregistrer les modifications</v-btn
                >
              </span>
            </template>
            <span
              >Vous n'avez pas les droits nécessaire pour accéder à ce module.
              Merci de contacter un administrateur.</span
            >
          </v-tooltip>
        </v-col>
      </v-row>
    </ValidateForm>

    <!--  Passer l'id de la fonction a assigner, à l'interieur du component, utilisé la modal user pour la selection -->
    <!--  Passer egalement l'id de la strucutre que l'on souhaite update.
  !!! cela doit aussi changer la liste des users de la modal -->
    <!--  Faire remonter les events jusqu'a ce component et stocké dans une clé ex: currentUserPresident  -->
    <!--  Stocker le components ddans components/User/User__AssignFonction  -->
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import {
  success_notification,
  error_notification,
} from "@/plugins/notifications";
import userAssign from "@/components/User/User__AssignUser.vue";
import adressAssign from "@/components/Adresse/Adresse__AssignAdresse.vue";
// import inputSelect from '@/components/utils/select'
import inputText from "@/components/utils/input";
import ValidateForm from "@/components/utils/form.vue";

export default {
  components: { inputText, userAssign, adressAssign, ValidateForm },

  data: () => ({
    mounted: false,
    loading: false,
    structure: null,
    affiliation: null,
    adresses: null,
    JUR_AgrementJeunesseSports: null,
    JUR_Siret: null,

    currentNomCorrespondant: null,
    currentAdresseCorrespondance: null,
    userPresident: null,
    userSecretaire: null,
    userTresorier: null,

    userCurrentPresident: null,
    userCurrentSecretaire: null,
    userCurrentTresorier: null,

    etapes_affilliations: [],
    ID_FonctionPresident: null,
    ID_FonctionSecretaire: null,
    ID_FonctionTresorier: null,

    UTI_Utilisateurs: [],
  }),

  async created() {
    this.$emit("updateTitle", "Informations generales");
    console.log("this.currentUser :>> ", this.currentUser);
    await Promise.all([
      this.getListEtapeAffiliation(),
      this.getListTypeFonctions(),
    ]);
    let allFonctionList = await this.getListTypeFonctions();

    (this.ID_FonctionPresident = allFonctionList.find(
      (fct) => fct.SlugFonction == "president"
    ).id),
      (this.ID_FonctionSecretaire = allFonctionList.find(
        (fct) => fct.SlugFonction == "bureau_secretaire"
      ).id),
      (this.ID_FonctionTresorier = allFonctionList.find(
        (fct) => fct.SlugFonction == "bureau_tresorier"
      ).id),
      await this.setData();
    this.mounted = true;
  },

  computed: {
    ...mapGetters("basics", ["getList"]),
    ...mapGetters("structure", [
      "currentStructure",
      "currentStructureId",
      "hasCodeFederal",
    ]),
    ...mapGetters("saisons", ["selectedSaison"]),
    ...mapGetters("user", ["can", "currentUser"]),

    colorEtapeAffiliation() {
      let slug = this.currentEtapeAffiliation.SlugEtapeAffiliation;
      if (slug == "en_attente") return "gray";
      if (slug == "en_cours_de_preparation") return "blue";
      if (slug == "ouverture_des_pre_renouvellements_de_licencies")
        return "gray";
      if (slug == "pre_affiliation_termine") return "green";
      return "gray";
    },

    canSubmit() {
      return this.can("gestion_des_saisons__informations_generales");
    },
  },

  methods: {
    ...mapActions("basics", [
      "getListEtapeAffiliation",
      "getListTypeFonctions",
    ]),
    ...mapActions("structure", [
      "getStructureAffiliation",
      "getStructureMembre",
      "getStructureAdresses",
      "updateUserFonctionOnStructure",
      "updateAffiliationInformationGeneral",
    ]),

    async setData() {
      this.adresses = await this.getStructureAdresses({
        ID_Structure: this.currentStructureId,
      });
      // TOCHECK added where and id_saison to fix error
      this.UTI_Utilisateurs = await this.getStructureMembre({
        // ID_Structure: this.currentStructureId,
        where: {
          ID_Structure: { _eq: this.currentStructureId },
          EST_Actif: { _eq: true },
          ID_Utilisateur: { _is_null: false },
        },
        // ID_Saison: this.selectedSaison.id
      });
      this.structure = { ...this.currentStructure };
      this.affiliation = await this.getStructureAffiliation({
        ID_Structure: this.currentStructureId,
        ID_Saison: this.selectedSaison.id,
      });
      this.JUR_AgrementJeunesseSports = this.structure.STR_Structure.JUR_AgrementJeunesseSports;
      this.JUR_Siret = this.structure.STR_Structure.JUR_Siret;

      if (this.affiliation.ID_EtapeAffiliation) {
        this.currentEtapeAffiliation = {
          ...this.getList.LIST_EtapeValidationAffiliation.find(
            (item) => item.id == this.affiliation.ID_EtapeAffiliation
          ),
        };
      }

      if (this.structure.STR_Structure.ID_NomCorrespondant) {
        this.currentNomCorrespondant = {
          ...this.UTI_Utilisateurs.find(
            (user) =>
              user.ID_Utilisateur ==
              this.structure.STR_Structure.ID_NomCorrespondant
          ),
        };
      }

      if (this.structure.STR_Structure.ID_AdresseCorrespondance) {
        this.currentAdresseCorrespondance = {
          ...this.adresses.find(
            (adr) =>
              adr.id == this.structure.STR_Structure.ID_AdresseCorrespondance
          ),
        };
      }

      this.userCurrentPresident = this.UTI_Utilisateurs.find(
        (user) => user.ID_Fonction == this.ID_FonctionPresident
      );
      this.userCurrentSecretaire = this.UTI_Utilisateurs.find(
        (user) => user.ID_Fonction == this.ID_FonctionSecretaire
      );
      this.userCurrentTresorier = this.UTI_Utilisateurs.find(
        (user) => user.ID_Fonction == this.ID_FonctionTresorier
      );

      if (this.userCurrentPresident)
        this.userPresident = { ...this.userCurrentPresident };
      if (this.userCurrentSecretaire)
        this.userSecretaire = { ...this.userCurrentSecretaire };
      if (this.userCurrentTresorier)
        this.userTresorier = { ...this.userCurrentTresorier };
      return;
    },

    async submit() {
      this.loading = true;
      try {
        let payload = {
          ID_Affiliation: this.affiliation.id,
          ID_Saison: this.selectedSaison.id,
          ID_Structure: this.currentStructureId,

          ID_EtapeAffiliation: this.currentEtapeAffiliation.id,

          ID_AdresseCorrespondance: this.currentAdresseCorrespondance
            ? this.currentAdresseCorrespondance.id
            : null,
          ID_NomCorrespondant: this.currentNomCorrespondant
            ? this.currentNomCorrespondant.ID_Utilisateur
            : null,
          JUR_Siret: this.JUR_Siret,
          JUR_AgrementJeunesseSports: this.JUR_AgrementJeunesseSports,

          ID_newUserPresident: this.userPresident
            ? this.userPresident.ID_Utilisateur
            : null,
          ID_newUserSecretaire: this.userSecretaire
            ? this.userSecretaire.ID_Utilisateur
            : null,
          ID_newUserTresorier: this.userTresorier
            ? this.userTresorier.ID_Utilisateur
            : null,

          ID_FonctionPresident: this.ID_FonctionPresident,
          ID_FonctionSecretaire: this.ID_FonctionSecretaire,
          ID_FonctionTresorier: this.ID_FonctionTresorier,

          ID_CurrentUserPresident: this.userCurrentPresident
            ? this.userCurrentPresident.ID_Utilisateur
            : null,
          ID_CurrentUserSecretaire: this.userCurrentSecretaire
            ? this.userCurrentSecretaire.ID_Utilisateur
            : null,
          ID_CurrentUserTresorier: this.userCurrentTresorier
            ? this.userCurrentTresorier.ID_Utilisateur
            : null,
        };
        await this.updateAffiliationInformationGeneral(payload);
        this.setData();
        success_notification("Mis à jour");
        this.loading = false;
      } catch (e) {
        error_notification("Oups, une erreur est survenue...");
        console.log(e);
      }
    },

    userSelectedNomCorrespondant(user) {
      this.currentNomCorrespondant = user;
    },

    async userFonctionPresidentSelected(user) {
      this.userPresident = user;
    },

    async userFonctionSecretaireSelected(user) {
      this.userSecretaire = user;
    },

    async userFonctionTresorierSelected(user) {
      this.userTresorier = user;
    },

    adresseSelected(adresse) {
      this.currentAdresseCorrespondance = adresse;
    },
  },
};
</script>
