<template>
  <div>
    <p class="text-danger font-weight-bold">Module bientôt disponible</p>
  </div>
</template>

<script>
export default {
  created() {
    this.$emit("updateTitle", "Représentants AG Nationale");
  },
};
</script>
